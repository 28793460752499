
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ProjectsListTabbed",
  components: {},
  props: {
    widgetClasses: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const itemsTabs = [];
    const title = "Projects widget";
    const description = "";
    const dataLoaded = false;
    return {
      itemsTabs,
      title,
      dataLoaded,
      description
    };
  },
  created: function() {
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/test/projectsWidgetDD")
        .then(data => {
          //update project tabs table
          this.title = "Latest projects";
          this.description = "Projects are sorted by job number";
          this.$nextTick(() => {
            this.itemsTabs = data.data.data;
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          console.log(response);
        });
    }
  }
});
