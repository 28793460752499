<template>
  <div
    :class="[!dataLoaded && 'overlay overlay-block {{widgetClasses}}']"
    class="card"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1" :key="title">{{
          title
        }}</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{ description }}</span>
      </h3>
      <div class="card-toolbar">
        <ul class="nav" role="tablist">
          <template v-for="(itemTab, tabIndex) in itemsTabs" :key="tabIndex">
            <li class="nav-item">
              <a
                class="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                :class="[itemTab.isActive && 'active']"
                data-bs-toggle="tab"
                role="tab"
                :data-bs-target="`#projects_list_widget_${tabIndex + 1}`"
                :href="`#projects_list_widget_${tabIndex + 1}`"
                >{{ itemTab.title }}</a
              >
            </li>
          </template>
        </ul>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <div
        class="overlay-layer rounded bg-white bg-opacity-5"
        :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="tab-content">
        <template v-for="(itemTab, tabIndex) in itemsTabs" :key="tabIndex">
          <!--begin::Tap pane-->
          <div
            class="tab-pane fade"
            :class="[itemTab.isActive && 'show active']"
            :id="`projects_list_widget_${tabIndex + 1}`"
            role="tabpanel"
          >
            <!--begin::Table container-->
            <div class="table-responsive">
              <!--begin::Table-->
              <table
                class="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
              >
                <!--begin::Table head-->
                <thead>
                  <tr class="border-0">
                    <th class="p-0 w-40px"></th>
                    <th class="p-0"></th>
                    <th class="p-0 w-100px"></th>
                    <th class="p-0 w-150px"></th>
                    <th class="p-0 w-100px"></th>
                    <th class="p-0"></th>
                  </tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                  <template v-for="(item, index) in itemTab.items" :key="index">
                    <tr>
                      <td>
                        <!--begin::Symbol-->
                        <div class="symbol symbol-50px me-5">
                          <span
                            :class="`bg-primary`"
                            class="symbol-label text-light flex-column fw-bold fs-2 pt-1"
                          >
                              <span class="fs-8 text-light flex-row">{{ item.jobNo.prefix }}</span>
                              {{item.jobNo.code}}
                          </span>
                        </div>
                      </td>
                      <td>
                        <a
                          href="#"
                          class="text-dark text-hover-primary mb-1 fs-6"
                          >{{ item.title }}</a
                        >
                        <span class="text-muted fw-bold d-block">{{
                          item.description
                        }}</span>
                      </td>
                      <td class="text-start">
                        <span :class="`badge badge-primary`">{{
                            item.type
                          }}</span>
                      </td>
                      <td>
                        <div class="d-flex flex-column w-100 me-2">
                          <div class="d-flex flex-stack mb-2">
                            <span class="text-muted me-2 fs-7 fw-bold">
                              {{ item.progress.value }}%
                            </span>
                          </div>

                          <div class="progress h-6px w-100">
                            <div
                              :class="`progress-bar bg-${item.progress.color}`"
                              role="progressbar"
                              :style="`width: ${item.progress.value}%`"
                              :aria-valuenow="item.progress.value"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <span :class="`badge badge-light-${item.color}`">{{
                          item.status
                        }}</span>
                      </td>
                      <td class="text-end">
                        <a
                          href="#"
                          class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                        >
                          <!--begin::Svg Icon | path: -->
                          <span class="svg-icon svg-icon-2">
                            <inline-svg
                              src="media/icons/duotone/Navigation/Arrow-right.svg"
                            />
                          </span>
                          <!--end::Svg Icon-->
                        </a>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <!--end::Table body-->
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Tap pane-->
        </template>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "ProjectsListTabbed",
  components: {},
  props: {
    widgetClasses: String
  },
  setup() {
    /*return {
      itemsTabs,
      title,
      description
    };*/
  },
  data: function() {
    const itemsTabs = [];
    const title = "Projects widget";
    const description = "";
    const dataLoaded = false;
    return {
      itemsTabs,
      title,
      dataLoaded,
      description
    };
  },
  created: function() {
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/test/projectsWidgetDD")
        .then(data => {
          //update project tabs table
          this.title = "Latest projects";
          this.description = "Projects are sorted by job number";
          this.$nextTick(() => {
            this.itemsTabs = data.data.data;
          });
          this.dataLoaded = true;
        })
        .catch(response => {
          console.log(response);
        });
    }
  }
});
</script>
